import { BREAKPOINT_MEDIA_QUERIES } from '../utils/breakpoints';
import { defineModule, nextTick } from '../utils/helpers';

const getElements = () => ({
  callMeBackElement: document.querySelector<HTMLElement>('.call-me-back'),
  callMeBackCardElement: document?.querySelector<HTMLElement>(
    '.call-me-back .call-me-back__card',
  ),
  callMeBackFormElement:
    document?.querySelector<HTMLFormElement>('.call-me-back form'),
  callMeBackButtonElement: document?.querySelector<HTMLElement>(
    '.call-me-back .call-me-back__button',
  ),
});

let lastScrollTop = 0;
const onScroll = () => {
  const { callMeBackElement } = getElements();

  // Hide call me back card on scroll down
  callMeBackElement?.classList.toggle(
    'call-me-back--hidden',
    window.scrollY > lastScrollTop,
  );

  lastScrollTop = window.scrollY;
};

const onFormSubmit = () => getElements().callMeBackButtonElement?.focus();

const onBreakpointChange = () => {
  const { callMeBackCardElement } = getElements();

  callMeBackCardElement?.classList.add('!transition-none');
  nextTick(() => {
    callMeBackCardElement?.classList.remove('!transition-none');
  });
};

export default defineModule(
  () => {
    const { callMeBackFormElement } = getElements();

    window.addEventListener('scroll', onScroll, {
      passive: true,
    });
    callMeBackFormElement?.addEventListener('submit', onFormSubmit, {
      passive: true,
    });
    BREAKPOINT_MEDIA_QUERIES.sm.addEventListener('change', onBreakpointChange, {
      passive: true,
    });
  },
  () => {
    const { callMeBackFormElement } = getElements();

    window.removeEventListener('scroll', onScroll);
    callMeBackFormElement?.removeEventListener('submit', onFormSubmit);
    BREAKPOINT_MEDIA_QUERIES.sm.removeEventListener(
      'change',
      onBreakpointChange,
    );
  },
);
