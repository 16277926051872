import { BREAKPOINT_MEDIA_QUERIES } from '../../utils/breakpoints';
import { defineModule } from '../../utils/helpers';

const getElements = () => ({
  navbarElement: document.querySelector<HTMLElement>('.navbar')!,
});

// eslint-disable-next-line @typescript-eslint/naming-convention
const { navbarElement: _navbarElement } = getElements();

const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach(({ isIntersecting, target }) => {
      const intersectionType = target.getAttribute(
        'data-navbar-intersection-type',
      );
      if (!intersectionType) return;

      const { navbarElement } = getElements();

      navbarElement.classList.toggle(
        `navbar--white-${intersectionType}`,
        isIntersecting,
      );
    });
  },
  {
    rootMargin: `-${_navbarElement.offsetHeight / 2}px 0px -${
      window.innerHeight - _navbarElement.offsetHeight / 2
    }px 0px`,
  },
);

const onBreakpointChange = () => {
  if (BREAKPOINT_MEDIA_QUERIES.lg.matches) {
    document
      .querySelectorAll<HTMLElement>('[data-navbar-intersection-type]')
      .forEach((contentBlock) => observer.observe(contentBlock));
  } else {
    observer.disconnect();
  }
};

export default defineModule(
  () => {
    onBreakpointChange();
    BREAKPOINT_MEDIA_QUERIES.lg.addEventListener('change', onBreakpointChange, {
      passive: true,
    });
  },
  () => {
    BREAKPOINT_MEDIA_QUERIES.lg.removeEventListener(
      'change',
      onBreakpointChange,
    );
  },
);
