import { throttle } from 'lodash-es';
import { defineModule } from '../utils/helpers';
import { BREAKPOINT_MEDIA_QUERIES } from '../utils/breakpoints';

const getElements = () => ({
  parallaxElements: document.querySelectorAll<HTMLElement>('[data-parallax]'),
});

/**
 * Calculate vertical center of an element
 * @param element HTML element
 */
export const getElementCenterY = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();
  return rect.top + rect.height / 2;
};

const parallaxStrengths: Record<string, number> = {
  '1': 1,
  '2': 2,
  '3': 3,
  // Relative (nested) parallax levels
  '1-2': 1 - 2,
  '3-2': 3 - 2,
};

// Function to apply the parallax effect
const applyParallaxEffect = () => {
  const { parallaxElements } = getElements();
  if (!parallaxElements || !BREAKPOINT_MEDIA_QUERIES.lg.matches) return;

  const { innerHeight: viewportHeight } = window;

  parallaxElements.forEach((el) => {
    const elementCenterY = getElementCenterY(el);
    const distanceFromCenter = elementCenterY - viewportHeight / 2;
    const parallaxType = el.dataset.parallax ?? '';
    const strength = parallaxStrengths[parallaxType] ?? 1;
    const translateY = (distanceFromCenter / viewportHeight) * strength * 50;

    el.style.transform = `translateY(${translateY}px)`;
  });
};

const throttleParallax = throttle(applyParallaxEffect, 100);

export default defineModule(
  () => {
    const { parallaxElements } = getElements();
    if (!parallaxElements) return;

    window.addEventListener('scroll', throttleParallax, {
      passive: true,
    });
    window.addEventListener('resize', throttleParallax, {
      passive: true,
    });

    throttleParallax();
  },
  () => {
    const { parallaxElements } = getElements();
    if (!parallaxElements) return;

    window.removeEventListener('scroll', throttleParallax);
    window.removeEventListener('resize', throttleParallax);
  },
);
