import { defineModule } from '../utils/helpers';

const getElements = () => ({
  postElements: document.querySelectorAll<HTMLElement>('[data-post]'),
  postsCategoryFilterElements: document.querySelectorAll<HTMLButtonElement>(
    '[data-posts-category-filter]',
  ),
});

let allCategories: number[] = [];
let selectedCategories: number[] = [];

const updatePosts = () =>
  getElements().postElements.forEach((postElement) => {
    const categories = postElement.getAttribute('data-post-categories');
    if (!categories) return;

    const parsedCategories = JSON.parse(categories) as number[];

    postElement.style.display = parsedCategories.some((category) =>
      selectedCategories.includes(category),
    )
      ? ''
      : 'none';
  });

const onClickPostCategoryFilter = ({ currentTarget }: MouseEvent) => {
  if (!currentTarget || !(currentTarget instanceof HTMLElement)) return;

  const category = currentTarget.getAttribute('data-posts-category-filter');
  if (!category) return;
  const categoryId = parseInt(category);

  const { postsCategoryFilterElements } = getElements();

  if (!selectedCategories.length) {
    postsCategoryFilterElements.forEach(
      (_postsCategoryFilterElement) =>
        currentTarget !== _postsCategoryFilterElement &&
        _postsCategoryFilterElement.classList.add('opacity-50'),
    );
  }

  const selectedCategoryIndex = selectedCategories.indexOf(categoryId);
  if (selectedCategoryIndex > -1) {
    selectedCategories.splice(selectedCategoryIndex, 1);

    currentTarget.classList.add('opacity-50');
  } else {
    selectedCategories.push(categoryId);

    currentTarget.classList.remove('opacity-50');
  }

  if (!selectedCategories.length) {
    selectedCategories = [...allCategories];

    postsCategoryFilterElements.forEach((_postsCategoryFilterElement) =>
      _postsCategoryFilterElement.classList.remove('opacity-50'),
    );
  }

  updatePosts();
};

export default defineModule(
  () => {
    if (!document.body.classList.contains('blog')) {
      return;
    }

    const { postsCategoryFilterElements } = getElements();

    postsCategoryFilterElements.forEach((postsCategoryFilterElement) => {
      const category = postsCategoryFilterElement.getAttribute(
        'data-posts-category-filter',
      );
      if (!category) return;

      const categoryId = parseInt(category);
      allCategories.push(categoryId);

      postsCategoryFilterElement.addEventListener(
        'click',
        onClickPostCategoryFilter,
        { passive: true },
      );
    });
  },
  () => {
    allCategories = [];
    selectedCategories = [];

    const { postsCategoryFilterElements } = getElements();

    postsCategoryFilterElements.forEach((postsCategoryFilterElement) => {
      const category = postsCategoryFilterElement.getAttribute(
        'data-posts-category-filter',
      );
      if (!category) return;

      const categoryId = parseInt(category);
      allCategories.push(categoryId);

      postsCategoryFilterElement.removeEventListener(
        'click',
        onClickPostCategoryFilter,
      );
    });
  },
);
