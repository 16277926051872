import {
  computePosition,
  autoUpdate,
  arrow,
  offset,
  flip,
  shift,
} from '@floating-ui/dom';
import { createElement, defineModule } from '../../utils/helpers';
import { getElements } from './form';

const autoUpdates: ReturnType<typeof autoUpdate>[] = [];

let tooltip: HTMLElement | null;

const getTooltip = () => {
  if (tooltip) return tooltip;

  tooltip = createElement(
    'div',
    {
      className: 'calculator-tooltip',
    },
    [
      createElement('p', {
        className: 'calculator-tooltip__content',
      }),
      createElement('span', {
        className: 'calculator-tooltip__arrow',
      }),
    ],
  );
  document.querySelector('.page-wrapper')!.prepend(tooltip);

  return tooltip;
};

const updateTooltipPosition = async (tooltipIcon: HTMLElement) => {
  const tip = getTooltip();

  const tipArrow = tip.querySelector<HTMLSpanElement>(
    '.calculator-tooltip__arrow',
  )!;

  const { middlewareData, placement, x, y } = await computePosition(
    tooltipIcon,
    tip,
    {
      placement: 'top',
      middleware: [
        offset(16),
        flip(),
        shift({
          padding: 8,
        }),
        arrow({
          element: tipArrow,
        }),
      ],
    },
  );

  Object.assign(tip.style, {
    left: `${x}px`,
    top: `${y}px`,
  });

  tip.classList.toggle('invisible', false);

  if (middlewareData.arrow && tipArrow) {
    const { x: arrowX, y: arrowY } = middlewareData.arrow;

    const side = placement.split('-')[0];

    const staticSide = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[side]!;

    Object.assign(tipArrow.style, {
      left: arrowX != null ? `${arrowX}px` : '',
      top: arrowY != null ? `${arrowY}px` : '',
      [staticSide]: `${-tipArrow.offsetWidth / 2}px`,
      transform: 'rotate(45deg)',
    });
  }
};
const hideTooltip = (e?: Event) => {
  e?.preventDefault();

  if (
    e instanceof PointerEvent &&
    e.type === 'pointerleave' &&
    e.pointerType === 'touch'
  ) {
    return;
  }

  const tip = getTooltip();
  tip.classList.toggle('invisible', true);

  while (autoUpdates.length) autoUpdates.pop()?.();
};

const showTooltip = async (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  if (e.type === 'click') return;

  const tip = getTooltip();

  if (!(e.currentTarget instanceof HTMLElement)) return;
  const toolTipIcon: HTMLElement = e.currentTarget;

  const tipP = tip.querySelector<HTMLParagraphElement>('p')!;

  if (
    !tip.classList.contains('invisible') &&
    tipP.innerHTML === toolTipIcon.dataset.tooltip
  ) {
    hideTooltip(e);
    return;
  }

  tipP.innerHTML = toolTipIcon.dataset.tooltip || '';

  await updateTooltipPosition(toolTipIcon);

  autoUpdates.push(
    autoUpdate(toolTipIcon, tip, async () => {
      await updateTooltipPosition(toolTipIcon);
    }),
  );
};

const toolTipEvents: [string, typeof showTooltip | typeof hideTooltip][] = [
  ['pointerenter', showTooltip],
  ['pointerleave', hideTooltip],
  ['focus', showTooltip],
  ['blur', hideTooltip],
  ['click', showTooltip],
];

export default defineModule(
  () => {
    const { calculatorForm } = getElements();
    if (!calculatorForm) return;

    const checkboxContainers = calculatorForm.querySelectorAll<HTMLDivElement>(
      '.frm_form_field.vertical_radio',
    );
    checkboxContainers.forEach((container) => {
      const descriptionElement =
        container.querySelector<HTMLDivElement>('.frm_description');
      if (!descriptionElement) return;

      const tooltips = descriptionElement.innerHTML.split('\n');
      const checkboxLabels = container.querySelectorAll<HTMLLabelElement>(
        '.frm_opt_container label',
      );

      let label;
      for (
        let i = 0;
        i < Math.min(tooltips.length, checkboxLabels.length);
        i += 1
      ) {
        label = checkboxLabels[i];

        const toolTipIcon = createElement('div', {
          className: 'tooltip-icon',
        });
        label.append(toolTipIcon);
        toolTipIcon.dataset.tooltip = tooltips[i];
        toolTipIcon.innerText = 'i';

        toolTipEvents.forEach(([event, listener]) => {
          toolTipIcon.addEventListener(event, listener, {
            passive: false,
          });
        });
      }
    });
  },
  () => {
    const { calculatorForm } = getElements();
    if (!calculatorForm) return;

    hideTooltip();

    const checkboxContainers = calculatorForm.querySelectorAll<HTMLDivElement>(
      '.frm_form_field.vertical_radio',
    );
    checkboxContainers.forEach((container) => {
      // container.removeEventListener('mouseleave', hideTooltip);
      container
        .querySelectorAll('label [data-tooltip]')
        .forEach((tooltipIcon) => {
          toolTipEvents.forEach(([event, listener]) => {
            tooltipIcon.removeEventListener(event, listener);
          });
          tooltipIcon.remove();
        });
    });

    tooltip?.remove();
    tooltip = null;
  },
);
