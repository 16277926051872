import { BREAKPOINT_MEDIA_QUERIES } from '../../utils/breakpoints';
import { defineModule } from '../../utils/helpers';
import { Overlay, toggleOverlay } from '../../utils/overlays';

const getElements = () => ({
  navbarElement: document.querySelector<HTMLElement>('.navbar'),
  navbarTogglerElement: document.querySelector<HTMLElement>('.navbar__toggler'),
  navbarMenuElement: document.querySelector<HTMLElement>('.navbar__menu'),
});

const onMenuPointerEnter = () => {
  if (!BREAKPOINT_MEDIA_QUERIES.lg.matches) return;

  const { navbarMenuElement, navbarElement } = getElements();

  navbarMenuElement?.classList.remove('delay-500');
  navbarElement?.classList.add('navbar--open');
};

const onMenuPointerLeave = () => {
  if (!BREAKPOINT_MEDIA_QUERIES.lg.matches) return;

  const { navbarMenuElement, navbarElement } = getElements();

  navbarMenuElement?.classList.add('delay-500');
  navbarElement?.classList.remove('navbar--open');
};

const onNavbarTogglerClick = () => {
  if (BREAKPOINT_MEDIA_QUERIES.lg.matches) return;

  const { navbarMenuElement, navbarElement } = getElements();

  if (navbarMenuElement?.classList.contains('delay-500')) {
    navbarMenuElement.classList.remove('delay-500');
  }

  toggleOverlay(Overlay.MENU, navbarElement?.classList.toggle('navbar--open'));
};

const onBreakpointChange = ({ matches }: MediaQueryListEvent) => {
  const { navbarElement } = getElements();

  /**
   * Open overlay only if menu is already open
   * and on mobile to hide overflow-y
   */
  toggleOverlay(
    Overlay.MENU,
    navbarElement?.classList.contains('navbar--open') && !matches,
  );
};

export default defineModule(
  () => {
    const { navbarMenuElement, navbarTogglerElement } = getElements();

    [navbarTogglerElement, navbarMenuElement].forEach((el) => {
      el?.addEventListener('pointerenter', onMenuPointerEnter, {
        passive: true,
      });
    });
    navbarMenuElement?.addEventListener('pointerleave', onMenuPointerLeave, {
      passive: true,
    });
    navbarTogglerElement?.addEventListener('click', onNavbarTogglerClick, {
      passive: true,
    });
    BREAKPOINT_MEDIA_QUERIES.lg.addEventListener('change', onBreakpointChange, {
      passive: true,
    });
  },
  () => {
    const { navbarMenuElement, navbarTogglerElement } = getElements();

    [navbarTogglerElement, navbarMenuElement].forEach((el) => {
      el?.removeEventListener('pointerenter', onMenuPointerEnter);
    });
    navbarMenuElement?.removeEventListener('pointerleave', onMenuPointerLeave);
    navbarTogglerElement?.removeEventListener('click', onNavbarTogglerClick);
    BREAKPOINT_MEDIA_QUERIES.lg.removeEventListener(
      'change',
      onBreakpointChange,
    );
  },
);
