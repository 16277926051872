import { createElement, defineModule } from '../../utils/helpers';

let originalTitle: string;

export const getElements = () => ({
  calculatorForm: document.querySelector<HTMLFormElement>(
    'form[id*="calculator"]',
  ),
  headerTitle: document.querySelector<HTMLHeadingElement>(
    '.content-block--header [data-header-title]',
  ),
  headerContent: document.querySelector<HTMLDivElement>(
    '.content-block--header [data-header-content]',
  ),
  headerButtons: document.querySelector<HTMLDivElement>(
    '.content-block--header [data-header-buttons]',
  ),
  step1btn: document.querySelector<HTMLElement>('[data-header-buttons] .btn'),
  rangeContainers: document.querySelectorAll<HTMLDivElement>(
    '.frm_forms .frm_range_container',
  ),
});

// eslint-disable-next-line func-names
const syncSliderInputs = function (this: HTMLInputElement) {
  const otherInput = Array.from(this.parentElement!.children).find(
    (el) => el instanceof HTMLInputElement && el !== this,
  );
  if (
    !(otherInput instanceof HTMLInputElement) ||
    otherInput.value === this.value
  )
    return;

  otherInput.value = this.value || this.min;
};

const handleInputSlider = (container: HTMLDivElement) => {
  const input = container.querySelector<HTMLInputElement>(
    'input[type="range"]',
  )!;
  const valSpan = container.querySelector<HTMLSpanElement>('.frm_range_value')!;

  const valueInput = createElement('input', {
    type: 'number',
    className: valSpan.classList.toString(),
    min: input.getAttribute('min'),
    max: input.getAttribute('max'),
    value: input.value,
  }) as HTMLInputElement;

  valSpan.replaceWith(valueInput);

  input.addEventListener('input', syncSliderInputs, {
    passive: true,
  });
  valueInput.addEventListener('input', syncSliderInputs, {
    passive: true,
  });
};

const handleFormPageChange = (e?: Event | JQuery.Event) => {
  const { hash: newHash, search: query } = window.location;
  let clickHref = '';
  if (e instanceof Event && e?.currentTarget) {
    clickHref = (e.currentTarget as HTMLElement)?.getAttribute('href') || '';
  }

  if (
    ![newHash, clickHref].includes('#start-coaching-value-calculator') &&
    query.indexOf('frm_page') === -1
  )
    return;

  const {
    calculatorForm,
    headerTitle,
    headerContent,
    headerButtons,
    rangeContainers,
  } = getElements();

  calculatorForm?.closest('.frm_forms')?.classList.toggle('hidden', false);
  headerButtons?.classList.toggle('hidden', true);

  const formHeading = calculatorForm?.querySelector<HTMLHeadingElement>(
    '.frm_section_heading > h3:first-child',
  )!;
  if (!formHeading) return;

  const formDescription = formHeading.nextElementSibling;

  headerTitle!.innerText = formHeading.innerText;
  headerTitle!.classList.toggle('uppercase', true);
  headerContent!.innerHTML = formDescription?.innerHTML || '';
  headerContent!.classList.toggle('font-bold', true);

  formHeading.classList.toggle('hidden', true);
  formDescription?.classList.toggle('hidden', true);

  rangeContainers.forEach((container) => handleInputSlider(container));
};

const onFormComplete = () => {
  const frmForms = document.querySelector(
    '.content-block--header + .frm_forms',
  );
  if (!frmForms) return;

  const { headerTitle, headerContent, headerButtons } = getElements();

  const status = frmForms.querySelector<HTMLElement>(
    '.frm_message[role=status]',
  )!.innerHTML;

  if (originalTitle) headerTitle!.innerHTML = originalTitle;

  headerContent!.innerHTML = status;

  frmForms.remove();

  const statusButtons = headerContent!.querySelectorAll<HTMLElement>('.btn');
  if (!statusButtons!.length) return;

  headerButtons!.innerHTML = '';
  headerButtons?.append(...statusButtons);
  headerButtons?.classList.toggle('hidden', false);
};

export default defineModule(
  () => {
    const { calculatorForm, step1btn } = getElements();

    if (!calculatorForm) return;

    originalTitle =
      document.querySelector<HTMLHeadingElement>('[data-header-title]')
        ?.innerText || '';

    calculatorForm.closest('.frm_forms')?.classList.toggle('hidden', true);

    window.addEventListener('hashchange', handleFormPageChange);
    step1btn?.addEventListener('click', handleFormPageChange);
    jQuery(document).on('frmPageChanged', handleFormPageChange);
    jQuery(document).on('frmFormComplete', onFormComplete);

    handleFormPageChange();
  },
  () => {
    const { calculatorForm, rangeContainers } = getElements();
    if (!calculatorForm) return;

    jQuery(document).off('frmPageChanged', handleFormPageChange);
    jQuery(document).off('frmFormComplete', onFormComplete);

    rangeContainers.forEach((container) => {
      container.querySelectorAll('input').forEach((input) => {
        input.removeEventListener('input', syncSliderInputs);
      });
    });
  },
);
